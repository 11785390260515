.confirmDeleteModal {
    margin:16px 0;
    padding:16px 0;
}
.confirmDeleteModalTitle {
    color:#26995F;
    border-bottom: 1px solid #26995F;
    margin-bottom:8px;
}
.actionButton {
    transition:color 0.3s;
}
.actionButton:hover {
    color:#26995F;
}
.flexWrapper {
    display:flex;
    flex-flow: row nowrap;
    width:100%;
    align-items: center;
}
.spacer {
    flex-grow:1;
}